.App {
  text-align: center;
  min-height: 100vh;
  background-color: #bdb6c9;
  padding: 24px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 20px;
  position: relative;
}

.App-logo-container {
  animation: App-logo-move 1s linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes App-logo-move {
  from {
    transform: translateX(0)
  }
  to {
    transform: translateX(100%)
  }
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text {
  margin: 0;
}

.ryan-img {
  margin: 40px auto;
  width: 50vw;
}
